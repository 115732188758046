import _ from "lodash";
import {
	FC,
	PropsWithChildren,
	createContext,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { ValidParams } from "./params.d";
import { removeFalsy } from "../../utils/removeFalsy";
import { useLocalStorage } from "usehooks-ts";
import { qrContext } from "../qr/qr";

interface IContext {
	params: ValidParams;
	setParams: (params: IContext["params"]) => void;
}

const context = createContext<IContext>(undefined!);

const Provider: FC<PropsWithChildren> = ({ children }) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const { qrUuid } = useParams();
	const [storedQrUuid, setQrUuid] = useLocalStorage("qrUuid", qrUuid);
	const lat = searchParams.get("lat") || undefined;
	const [storedLat, setLat] = useLocalStorage("lat", lat);
	const lon = searchParams.get("lon") || undefined;
	const [storedLon, setLon] = useLocalStorage("lon", lon);
	const zoom = searchParams.get("zoom") || undefined;
	const [storedZoom, setZoom] = useLocalStorage("zoom", zoom);
	const store = searchParams.get("store") || undefined;
	// const [storedStore, setStore] = useLocalStorage("store", store);
	const mode = searchParams.get("mode") || undefined;
	// const [storedMode, setMode] = useLocalStorage("mode", mode);

	const { data: qr } = useContext(qrContext);

	const initialParams: ValidParams = {};

	initialParams["lon"] = Number(lon) || Number(storedLon);
	initialParams["lat"] = Number(lat) || Number(storedLat);
	initialParams["zoom"] = Number(zoom) || Number(storedZoom);

	// Override queried parameters with qr parameters
	if (qr) {
		if (qr["defaultParams"]) {
			if (qr["defaultParams"]["position"]) {
				const force = qr["defaultParams"]["position"]["force"];

				// Lat override
				if (qr["defaultParams"]["position"]["lat"]) {
					const lat = qr["defaultParams"]["position"]["lat"];
					if (force) {
						initialParams["lat"] = lat || Number(storedLat);
					} else {
						initialParams["lat"] = Number(storedLat) || lat;
					}
				}

				// Lon override
				if (qr["defaultParams"]["position"]["lon"]) {
					const lon = qr["defaultParams"]["position"]["lon"];
					if (force) {
						initialParams["lon"] = lon || Number(storedLon);
					} else {
						initialParams["lon"] = Number(storedLon) || lon;
					}
				}

				// Zoom override
				if (qr["defaultParams"]["position"]["zoom"]) {
					const zoom = qr["defaultParams"]["position"]["zoom"];
					if (force) {
						initialParams["zoom"] = zoom || Number(storedZoom);
					} else {
						initialParams["zoom"] = Number(storedZoom) || zoom;
					}
				}
			}
		}
	}

	initialParams["store"] = store;
	initialParams["mode"] = mode;

	const [currentParams, setCurrentParams] = useState<typeof initialParams>(initialParams);

	useEffect(() => {
		if (qr) {
			if (qr["uuid"] !== storedQrUuid) {
				setQrUuid(qr["uuid"]);
			}
		}

		if (currentParams["lat"]) {
			if (String(currentParams["lat"]) !== storedLat) {
				setLat(String(currentParams["lat"]));
			}
		}

		if (currentParams["lon"]) {
			if (String(currentParams["lon"]) !== storedLon) {
				setLon(String(currentParams["lon"]));
			}
		}

		if (currentParams["zoom"]) {
			if (String(currentParams["zoom"]) !== storedZoom) {
				setZoom(String(currentParams["zoom"]));
			}
		}
	}, [
		qr,
		currentParams,
		storedQrUuid,
		setQrUuid,
		storedLat,
		setLat,
		storedLon,
		setLon,
		storedZoom,
		setZoom,
	]);

	const setParams = (params: ValidParams) => {
		const paramsAsStringValue = _.mapValues(removeFalsy(params), (param) => String(param));

		setCurrentParams(params);
		setSearchParams(paramsAsStringValue, { replace: true });
	};

	const _memParams = useMemo(() => removeFalsy(currentParams), [currentParams]);

	return <context.Provider value={{ params: _memParams, setParams }}>{children}</context.Provider>;
};

export { context as paramsContext, Provider as ParamsProvider };
