import { FC, PropsWithChildren, createContext, useMemo } from "react";
import { useEffectOnce, useLocalStorage } from "usehooks-ts";
import { v4 as uuidv4 } from "uuid";

const context = createContext<string>(undefined!);

const Provider: FC<PropsWithChildren> = ({ children }) => {
	const [session, setSession] = useLocalStorage("session", uuidv4());

	useEffectOnce(() => setSession(session));

	const _memSession = useMemo(() => session, [session]);

	return <context.Provider value={_memSession}>{children}</context.Provider>;
};

export { context as sessionContext, Provider as SessionProvider };
