import _ from "lodash";
import { FC, PropsWithChildren, createContext, useContext, useEffect, useRef } from "react";
import { geolocationContext } from "../../context/geolocation/geolocation";
import { qrContext } from "../../context/qr/qr";
import { queriesContext } from "../../context/queries/queries";
import { sessionContext } from "../../context/session/session";
import { useLocalStorage } from "usehooks-ts";
import axios from "axios";
import UAParser from "ua-parser-js";

type InteractionType =
	| "QR:SCAN"
	| "QR:SEARCH"
	| "STORE:SELECTION"
	| "STORE:MODAL_OPEN"
	| "STORE:ROUTING"
	| "STORE:WEBSITE"
	| "STORE:EMAIL"
	| "STORE:SHARE"
	| "STORE:PHONE";

interface Interaction {
	session: string;
	qr: string;
	store: string;
	item: string;
	search: string;
	results: string;
	radius: string;
	user: string;
	previewCode: string;
	type: InteractionType;

	source: string;
}

const ua = new UAParser();

function isIframe() {
	try {
		return window.self !== window.top;
	} catch (e) {
		return true;
	}
}

const context = createContext<(interactionType: InteractionType, store?: string) => void>(
	undefined!
);

const Layer: FC<PropsWithChildren> = ({ children }) => {
	const [_previewCode] = useLocalStorage("previewCode", "");
	const { data: geolocatedPosition } = useContext(geolocationContext);
	const { data: qr, error: qrError } = useContext(qrContext);
	const { query } = useContext(queriesContext);
	const lastInteraction = useRef<Interaction>();
	const session = useContext(sessionContext);

	const trigger = (interactionType: InteractionType, store?: string) => {
		if (!session) {
			console.warn("Warn: Session");
			return;
		}

		if (!query) {
			console.warn("Warn: Query");
			return;
		}

		const { lat, lng: lon } = query["1"];

		const interaction: Interaction = {
			session: session,
			qr: "-",
			store: "-",
			item: "-",
			search: [lat, lon].join(","),
			results: String(query[0].length),
			radius: String(query[2]),
			user: "-",
			type: interactionType,
			source: JSON.stringify(ua.getResult()),
			previewCode: _previewCode,
		};

		if (store) {
			interaction["store"] = store;
		}

		if (isIframe()) {
			interaction["source"] = "iFrame";
		}

		if (qr) {
			interaction["qr"] = qr["uuid"];

			if (qr["item"]?.["uuid"]) {
				interaction["item"] = qr["item"]["uuid"];
			}
		}

		if (geolocatedPosition) {
			const {
				coords: { latitude: lat, longitude: lon },
			} = geolocatedPosition;
			interaction["user"] = [lat, lon].join(",");
		}

		if (!_.isEqual(lastInteraction["current"], interaction)) {
			storeInteraction(interaction);
			lastInteraction["current"] = interaction;
		}
	};

	const storeInteraction = (interaction: Interaction) => {
		axios.post(`${process.env.REACT_APP_API_HOST}/session/${session}`, {
			...interaction,
		});
	};

	useEffect(() => {
		if (!!qrError) {
			if (lastInteraction["current"]) {
				trigger("QR:SEARCH");
			} else {
				trigger("QR:SCAN");
			}
		} else if (!!qr && !!session && !!query) {
			if (lastInteraction["current"]) {
				trigger("QR:SEARCH");
			} else {
				trigger("QR:SCAN");
			}
		}
	}, [qr, qrError, session, query]);

	return <context.Provider value={trigger}>{children}</context.Provider>;
};

export { context as analyticsContext, Layer as AnalyticsLayer };
