import _ from "lodash";
import { css } from "../../../ui/utils";
import { mapContext } from "../../../context/map/map";
import { CSSProperties, FC, useContext, useEffect, useRef, useState } from "react";
import { icon } from "../../../ui/styles";
import { queriesContext } from "../../../context/queries/queries";
import { ReactComponent as IconOkeanys } from "./../../../assets/okeanYs-fill-gray.svg";
import { ReactComponent as IconGeoloc } from "./../../../assets/geoloc.svg";
import { TextInput } from "../../../ui/components/TextInput/textInput";
import { useDebounce, useFetch, useLocalStorage } from "usehooks-ts";
import { useTranslation } from "react-i18next";
import axios from "axios";
import styles from "./textInput.module.scss";
import { Paper } from "../../../ui/components/Paper/paper";
import { geolocationContext } from "../../../context/geolocation/geolocation";
import { isGeolocationPositionError } from "../../../utils/isGeolocationError";
import { modalsContext } from "../../../context/modals/modals";

const Location: FC<{ className?: string; style?: CSSProperties }> = ({ className, style = {} }) => {
	const { t } = useTranslation();

	const elRef = useRef<HTMLInputElement>(null);

	const [value, setValue] = useState("");
	const [searchedLocations, setSearchedLocations] = useLocalStorage<string[]>("locations", []);
	const debouncedValue = useDebounce(value, 500);
	const { ref: mapRef } = useContext(mapContext);
	const { query, search } = useContext(queriesContext);
	const [, setModal] = useContext(modalsContext);
	const { data: geolocatedPosition, start } = useContext(geolocationContext);

	const { data: options } = useFetch<string[]>(
		!_.isEmpty(debouncedValue)
			? `${process.env.REACT_APP_API_HOST}/geocoder/autocomplete?input=${debouncedValue}`
			: undefined
	);
	const optionsRef = useRef(options);

	useEffect(() => {
		if (query) {
			if (query[3]) {
				setValue(query[3]);
			}
		}
	}, [query]);

	useEffect(() => {
		if (!!options?.length) {
			optionsRef.current = options;
		}
	}, [options]);

	const panAndSearch = (lat: number, lon: number) => {
		setModal("");
		mapRef.current?.once("moveend", (e) => {
			search();
		});
		mapRef.current?.panTo([lat, lon]);
	};

	const onSubmit = (value: string) => {
		axios
			.get<string[]>(`${process.env.REACT_APP_API_HOST}/geocoder/autocomplete?input=${value}`)
			.then(({ data }) => {
				const autocompleted = _.first(data);

				if (!autocompleted) {
					return;
				}

				axios
					.get<{ lat: number; lon: number }>(`${process.env.REACT_APP_API_HOST}/geocoder/search`, {
						headers: {
							authorization: `code ${process.env.REACT_APP_PRIVATE_TOKEN}`,
						},
						params: {
							input: autocompleted,
						},
					})
					.then(({ data }) => {
						setValue(autocompleted);
						setSearchedLocations([...searchedLocations, autocompleted]);
						elRef.current?.blur();
						panAndSearch(data.lat, data.lon);
					})
					.catch((error) => {});
			})
			.catch((error) => {});
	};

	const isGeolocationAvailable =
		!!geolocatedPosition && !isGeolocationPositionError(geolocatedPosition);

	const onGeolocSubmit = () => {
		if (!isGeolocationAvailable) {
			return;
		}

		axios
			.get<string>(`${process.env.REACT_APP_API_HOST}/geocoder/reverse`, {
				headers: {
					authorization: `code ${process.env.REACT_APP_PRIVATE_TOKEN}`,
				},
				params: {
					lat: geolocatedPosition.coords.latitude,
					lon: geolocatedPosition.coords.longitude,
				},
			})
			.then(({ data }) => {
				setValue(data);
			});

		elRef.current?.blur();
		panAndSearch(geolocatedPosition.coords.latitude, geolocatedPosition.coords.longitude);
	};

	const autocompleteOptions = [
		isGeolocationAvailable ? (
			<Paper
				key={0}
				className={css(styles["option"], styles["option--important"], styles["my-position"])}
				onMouseDown={(e) => e.preventDefault()}
				onClick={onGeolocSubmit}>
				<IconGeoloc />
				<span>{t("widget_location_input_autocomplete-my-position-available")}</span>
			</Paper>
		) : (
			<Paper
				key={0}
				className={css(styles["option"], styles["option--important"], styles["my-position"])}
				onMouseDown={(e) => e.preventDefault()}
				onClick={start}>
				<IconGeoloc />
				<span>{t("widget_location_input_autocomplete-my-position-disabled")}</span>
			</Paper>
		),

		...(!_.isEmpty(value)
			? (options || optionsRef.current || [])?.map((option, index) => (
					<Paper
						key={index + 1}
						className={styles["option"]}
						onMouseDown={(e) => e.preventDefault()}
						onClick={() => onSubmit(option)}>
						{option}
					</Paper>
			  ))
			: _.take(_.uniq(_.reverse([...searchedLocations])), 5).map((option, index) => (
					<Paper
						key={index + 1}
						className={styles.option}
						onMouseDown={(e) => e.preventDefault()}
						onClick={() => onSubmit(option)}>
						{option}
					</Paper>
			  ))),
	].flatMap((i) => (!!i ? [i] : []));

	return (
		<div className={css(styles.textInput, className)} style={style}>
			<form
				onSubmit={(e) => {
					e.preventDefault();
					e.stopPropagation();
					onSubmit(value);
				}}>
				<TextInput
					ref={elRef}
					direction={"down"}
					options={autocompleteOptions}
					value={value}
					onChange={({ target: { value: currentValue } }) => setValue(currentValue)}
					placeholder={t("widget_location_input-placeholder") || undefined}>
					<IconOkeanys className={css(icon.inline, icon.medium, icon.spaced)} />
				</TextInput>
			</form>
		</div>
	);
};

export { Location };
